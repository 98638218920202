import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/dash',
    name:'Dashboard',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Dashboard.vue')
  },
  {
    path: '/favourites',
    name:'Favourites',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Favourites.vue')
  },
  {
    path: '/wallet/:slug',
    name:'Wallet',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Wallet.vue')
  },
  {
    path: '/token/:slug',
    name:'Token',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Token.vue')
  },
  {
    path: '/token/:slug/:address',
    name:'Token',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Token.vue')
  },
  {
    path: '/explore',
    name:'Explore',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Explore.vue')
  },
  {
    path: '/explore/:slug',
    name:'Explore Tokens',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/ExploreTokens.vue')
  },
  {
    path: '/login',
    name:'Login',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Login.vue')
  },
  {
    path: '/signup',
    name:'Sign Up',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Register.vue')
  },
  {
    path: '/settings',
    name:'Settings',
    component: () => import(/* webpackChunkName: "home" */ '../views/admin/Settings.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
