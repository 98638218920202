import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss'

const app = createApp(App);
app
.use(router)
.use(VueSweetalert2)
.mount('#app')

app.config.globalProperties.$api = '/backend/endpoints';
//app.config.globalProperties.$api = 'http://localhost/trackwallets/endpoints';